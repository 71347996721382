/**
* Layout component that queries for data
* with Gatsby's useStaticQuery component
*
* See: https://www.gatsbyjs.org/docs/use-static-query/
*/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
const data = useStaticQuery(graphql`
query SiteTitleQuery {
  site {
    siteMetadata {
      title
    }
  }
}
`)

return (
    <div className="background-primary">
        <section id="layout-content">
            {children}
        </section>
    </div>
)
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
